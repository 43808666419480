@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use '~booksprout-app/src/css/functions/rem-fn' as *
@use 'src/css/functions/get-color' as *
@use '~booksprout-app/src/css/mixins/media' as media-query
@use 'src/css/variables/media' as media

.bs-w-about
  article
    padding-block-end: rem-fn(80)
    padding-inline: rem-fn(30)

    &:first-child
      padding-block-start: rem-fn(30)

  @include media-query.from(media.$md-min)
    margin-inline: auto
    max-inline-size: rem-fn(media.$content-max-width)

.bs-w-ab-intro
  p
    margin-block-end: rem-fn(60)

    span
      color: get-color(bs-b)

  .bs-w-card
    color: get-color(bs-gm)

    &:not(:last-child)
      margin-block-end: rem-fn(60)

    svg
      color: get-color(bs-g)
      margin-block-end: rem-fn(40)

  .bs-w-m-e-four
    border-color: get-color(bs-g)

  @include media-query.from(media.$md-min)
    display: grid
    grid-template-areas: 'heading heading overlap reviewers' 'subtext subtext overlap reviewers' 'card1 card2 overlap reviewers'
    grid-template-columns: repeat(2, rem-fn(374)) rem-fn(86) rem-fn(450)

    h1
      grid-area: heading
      margin-block-start: rem-fn(84)
      max-inline-size: rem-fn(629)

    p
      grid-area: subtext
      max-inline-size: rem-fn(538)

    .bs-w-card
      max-inline-size: rem-fn(374)
      padding-inline: 0
      text-align: left

      &:not(:last-child)
        margin-block-end: 0

      &:first-of-type
        grid-area: card1

      &:last-of-type
        grid-area: card2-start / card2-start / overlap-end / overlap-end
        margin-inline-start: rem-fn(100)

      svg
        margin-block-end: rem-fn(30)

    .bs-w-member-ellipse
      grid-area: overlap-start / overlap-start / reviewers-end / reviewers-end

.bs-w-ab-team
  h2
    margin-block-end: rem-fn(40)

  @include media-query.from(media.$md-min)
    display: grid
    gap: rem-fn(35)
    grid-template-columns: repeat(2, 1fr)

    h2
      grid-column: span 2
      margin-block-end: rem-fn(10)

  @include media-query.from(media.$md-max)
    grid-template-columns: repeat(3, 1fr)

    h2
      grid-column: span 3

.bs-w-booksprout-logo
  align-items: center
  background-color: get-color(bs-g)
  border-radius: 50%
  block-size: rem-fn(92)
  display: flex
  justify-content: center
  inline-size: rem-fn(92)

  img
    border-radius: 0 !important
