@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use '~booksprout-app/src/css/functions/rem-fn' as *
@use '~booksprout-app/src/css/functions/line-height-fn' as *
@use 'src/css/functions/get-color' as *
@use '~booksprout-app/src/css/mixins/media' as media-query
@use 'src/css/variables/media' as media

.bs-w-team-member
  display: grid
  gap: rem-fn(30)
  grid-template-columns: max-content 1fr
  margin: 0

  &:not(:last-of-type)
    margin-block-end: rem-fn(60)

  figcaption
    font-size: rem-fn(16)
    grid-column: span 2
    line-height: line-height(22, 16)

  @include media-query.from(media.$md-min)
    align-items: baseline

    img
      grid-column: span 2

    figcaption
      min-block-size: rem-fn(202)

    &:not(:last-of-type)
      margin-block-end: 0

.bs-w-t-m-name-role
  color: get-color(bs-b)
  display: flex
  flex-flow: column
  justify-content: center

  h3
    margin-block-end: rem-fn(10)

.bs-w-t-m-social-links
  display: flex
  column-gap: rem-fn(20)
  grid-column: span 2

  a
    color: #444

    svg
      block-size: rem-fn(20)
      inline-size: rem-fn(20)

  @include media-query.from(media.$md-min)
    grid-column: 2
    margin-inline-start: auto
